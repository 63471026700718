/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";

/* APIS */
import { getBlogs } from "../service/api";

/* CONFIG */
import { IMAGE_URL } from "../config";

const BlogBox = (props) => {
  return (
    <Link
      to={"/blog-detail/" + props?.blogDetails?._id}
      style={{ textDecoration: "none" }}
    >
      <Card className="blog-box">
        <Card.Body className="blog-box-body">
          <img src={IMAGE_URL + props.image} alt={props.title} />
          <div className="details">
            <h5>{props.title}</h5>
          </div>
        </Card.Body>
        <Card.Footer className="blog-box-footer">
          <p className="date">
            {moment(`${new Date(props?.datePublished)}`).format(
              "DD MMMM, YYYY"
            )}
          </p>
        </Card.Footer>
      </Card>
    </Link>
  );
};

const AboutAndBlogs = () => {

  /* STATE VARIABLES */
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(9);
  const [blogsData, setBlogsData] = useState([]);

  /* GET BLOGS LISTINGS */
  const getBlogsList = () => {
    setLoading(true);
    const obj = {};
    obj.page = page;
    obj.sizePerPage = pageSize;

    getBlogs(obj)
      .then((res) => {
        if (res?.status) {
          setBlogsData(res?.data?.docs);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally((err) => {
        setLoading(false);
      });

    return () => {
      setBlogsData([]);
    };
  };

  /* GET BLOGS ONLOAD */
  useEffect(() => {
    getBlogsList();
  }, []);


  useEffect(() => {
    getBlogsList();
  }, [page]);

  return (
    <section className="about-and-blogs">
      <div className="bg-overlay">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-xl-4 col-sm-12">
              <img
                src={require("../assets/michael-avendano.webp")}
                alt="Michael Avendano"
              />
            </div>
            <div className="col-xl-7 col-sm-12">
              <div className="row">
                <div className="col-xl10 col-sm-12">
                  <div className="heading">
                    <h2>MICHAEL AVENDANO</h2>
                    <p>
                      Michael Avendano is the Managing Broker of Amberwood Real
                      Estate, Inc. He acquired his Real Estate License in 2006
                      and his Brokers License in 2013. Throughout the years he
                      has represented over 100 families and sold over 50 million
                      in Real Estate spanning all over Southern California.
                    </p>
                    <p>
                      Michael has the knowledge and experience to tackle the
                      challenges of today's ever changing market. He prides
                      himself in always being there for his buyers and sellers.
                      He always listens and works tirelessly to help his clients
                      achieve their goals.
                    </p>
                    <p>
                      He is a member of the Rancho Southeast Association of
                      Realtors, California Association of Realtors and National
                      Association of Realtors. Michael also owns a successful
                      Property Management business. Aside from being dedicated
                      to his career, he is devoted to his family, happily
                      married and father of three.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            {blogsData.slice(0, 4).map((blog) => {
              return (
                <div className="col-xl-3 col-lg-6 col-sm-12" key={blog.id}>
                  <BlogBox
                    image={blog?.image}
                    title={blog?.name}
                    datePublished={blog?.startDate}
                    blogDetails={blog}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutAndBlogs;